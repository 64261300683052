import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    BooleanField,
    NumberField,
    ImageField,
    DateField,
    Create,
    Edit,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    BooleanInput, ReferenceField, SelectField, useRecordContext, UrlField, NumberInput
} from 'react-admin';
import CreateObjectTransform from "../../CreateObjectTransform";

const categoriesFilters = [
    <TextInput source="name.en" label="Search by title" alwaysOn/>,
    <BooleanInput source="isBlocked" optionText="isBlocked"/>,

];


export const CategoriesList = props => (<List {...props} perPage={100} filters={categoriesFilters} sort={{ field: 'sortOrder', order: 'DESC' }}>
    <Datagrid rowClick="edit">
        <BooleanField source="isBlocked"/>
        <TextField source="name.en" label="Title"/>
        <NumberField source="sortOrder" label="Sorting order"/>
    </Datagrid>
</List>)

export const CategoryEdit = props => (<Edit {...props}>
    <SimpleForm>
        <TextInput source="id" disabled/>
        <TextInput source="name.en" label="Title"/>
        <NumberInput source="sortOrder" label="Sorting order" value={0}/>
        <BooleanInput source="isBlocked"/>
    </SimpleForm>
</Edit>);

export const CategoryCreate = props => (
    <Create {...props} transform={CreateObjectTransform}>
        <SimpleForm>
            <TextInput source="name.en" label="Title"/>
            <BooleanInput source="isBlocked"/>
            <NumberInput source="sortOrder" label="Sorting order" defaultValue={0}/>
        </SimpleForm>
    </Create>);
