import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    BooleanField,
    NumberField,
    ImageField,
    SingleFieldList,
    ReferenceArrayInput,
    SelectArrayInput,
    ChipField,
    DateField,
    Edit,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    BooleanInput, ReferenceField, SelectField, useRecordContext, UrlField, ReferenceArrayField
} from 'react-admin';

const productFilters = [
    <TextInput source="title" label="Search by title" alwaysOn/>,
    <TextInput source="description" label="Search by description" alwaysOn/>,
    <ReferenceInput source="sellerId" label="User" reference="users" allowEmpty>
        <TextInput optionText="id" source="id"/>
    </ReferenceInput>,
    <BooleanInput source="isSold" optionText="isSold"/>,
    <BooleanInput source="isFree" optionText="isFree"/>,

];

const CustomDateField = (props) => {
    const record = useRecordContext(props);
    return <span soure={new Date(record.createDate * 1000).toLocaleString()}> {new Date(record.createDate * 1000).toLocaleString()}  </span>
}

const VideoField = (props) => {
    const record = useRecordContext(props);
    return <video src={`${record.video}`} controls width="120" height="160"></video>;
}
const VideoFieldBig = (props) => {
    const record = useRecordContext(props);
    return <video src={`${record.video}`} controls width="480" height="640"></video>;
}

export const ProductList = props => (<List {...props} perPage={100} filters={productFilters}>
    <Datagrid rowClick="edit">
        <BooleanField source="isBlocked"/>
        <VideoField source="preview"/>
        <TextField source="id"/>
        <TextField source="title"/>
        <ReferenceField reference="categories" source="categoryId">
            <TextField source="name.en"/>
        </ReferenceField>
        <ReferenceArrayField reference="tags" source="tags">
            <SingleFieldList>
                <ChipField source="id"/>
            </SingleFieldList>
        </ReferenceArrayField>
        <BooleanField source="isSold"/>
        <BooleanField source="isFree"/>
        <NumberField source="views"/>
        <NumberField source="likes"/>
        <UrlField source="video"/>
        <ReferenceField source="sellerId" reference="users">
            <TextField optionText="User Id" source="id"/>
        </ReferenceField>
        <CustomDateField source="createDate" />
    </Datagrid>
</List>)

export const ProductEdit = props => (<Edit {...props}>
        <SimpleForm>
            <TextInput source="title"/>
            <ReferenceInput reference="categories" source="categoryId">
                <SelectInput optionText="name.en"/>
            </ReferenceInput>
            <ReferenceArrayInput reference="tags" source="tags">
                <SelectArrayInput optionText="id" source="id"/>
            </ReferenceArrayInput>
            <BooleanInput source="isBlocked"/>
            <VideoFieldBig source="preview"/>
            <ReferenceInput source="sellerId" reference="users" >
                <SelectInput optionText="id"/>
            </ReferenceInput>
            <TextInput source="id" disabled/>
            <CustomDateField source="createDate" />
        </SimpleForm>
    </Edit>
);
