import logo from './logo.svg';
import './App.css';
import {Admin, Resource, ListGuesser} from 'react-admin';
import {
    FirebaseAuthProvider,
    FirebaseDataProvider,
} from 'react-admin-firebase';
import {ProductEdit, ProductList} from "./components/products/ProductsList";
import {UserEdit, UserList} from "./components/users/UserList";
import {TagEdit, TagsList} from "./components/tags/TagsList";
import {CategoriesList, CategoryCreate, CategoryEdit} from "./components/categories/CategoriesList";
import {LegalDocsEdit, LegalDocsList} from "./components/legaldocs/LegalDocsList";
import {ReportEdit, ReportsList} from "./components/reports/ReportsList";
import {config} from './firebaseConfig'
import {QuestionReportsList, QuestionReportEdit} from "./components/question_reports/QuestionReportsList";
import {QuestionList, QuestionEdit} from "./components/questions/Questions";

// All options are optional
const options = {
    // // Use a different root document to set your resource collections, by default it uses the root collections of firestore
    // rootRef: 'root-collection/some-doc' | () => 'root-collection/some-doc',
    // // Your own, previously initialized firebase app instance
    // app: firebaseAppInstance,
    // Enable logging of react-admin-firebase
    logging: true,
    // // Resources to watch for realtime updates, will implicitly watch all resources by default, if not set.
    // watch: ['posts'],
    // // Resources you explicitly dont want realtime updates for
    // dontwatch: ['comments'],
    // Authentication persistence, defaults to 'session', options are 'session' | 'local' | 'none'
    persistence: 'session',
    // Disable the metadata; 'createdate', 'lastupdate', 'createdby', 'updatedby'
    disableMeta: false,
    // Have custom metadata field names instead of: 'createdate', 'lastupdate', 'createdby', 'updatedby'
    renameMetaFields: {
        createdByAdminAt: 'createdByAdminAt', // default: 'createdate'
        createdByAdmin: 'createdByAdmin', // default: 'createdby'
        updatedByAdminAt: 'updatedByAdminAt', // default: 'lastupdate'
        updatedByAdmin: 'updatedByAdmin', // default: 'updatedby'
    },
    // Prevents document from getting the ID field added as a property
    dontAddIdFieldToDoc: false,
    // Adds 'deleted' meta field for non-destructive deleting functionality
    // NOTE: Hides 'deleted' records from list views unless overridden by filtering for {deleted: true}
    softDelete: false,
    // Changes meta fields like 'createdby' and 'updatedby' to store user IDs instead of email addresses
    associateUsersById: true,
    // Casing for meta fields like 'createdby' and 'updatedby', defaults to 'lower', options are 'lower' | 'camel' | 'snake' | 'pascal' | 'kebab'
    metaFieldCasing: 'camel',
    // Instead of saving full download url for file, save just relative path and then get download url
    // when getting docs - main use case is handling multiple firebase projects (environments)
    // and moving/copying documents/storage files between them - with relativeFilePaths, download url
    // always point to project own storage
    relativeFilePaths: false,
    // Add file name to storage path, when set to true the file name is included in the path
    useFileNamesInStorage: false,
    // Use firebase sdk queries for pagination, filtering and sorting
    lazyLoading: {
        enabled: false
    },
    // Logging of all reads performed by app (additional feature, for lazy-loading testing)
    firestoreCostsLogger: {
        enabled: false
        // localStoragePrefix // optional
    }
}

const dataProvider = FirebaseDataProvider(config, options);
const authProvider = FirebaseAuthProvider(config, options);

const App = () => (
    <Admin dataProvider={dataProvider} authProvider={authProvider}>
        <Resource name="products" list={ProductList} edit={ProductEdit}/>
        <Resource name="categories" list={CategoriesList} edit={CategoryEdit} create={CategoryCreate}/>
        <Resource name="tags" list={TagsList} edit={TagEdit}/>
        {/*<Resource name="zips" list={ZipsList} edit={ZipsEdit} create={ZipsCreate}/>*/}
        {/*<Resource name="regions" list={RegionsList} edit={RegionEdit} create={RegionCreate}/>*/}
        <Resource name="users" list={UserList} edit={UserEdit}/>
        <Resource name="reports" list={ReportsList} edit={ReportEdit}/>
        <Resource name="community_questions" list={QuestionList} edit={QuestionEdit}/>
        <Resource name="question_report" list={QuestionReportsList} edit={QuestionReportEdit}/>
        <Resource name="legaldocs" list={LegalDocsList} edit={LegalDocsEdit}/>
    </Admin>
);
export default App;

