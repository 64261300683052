import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    BooleanField,
    NumberField,
    ImageField,
    DateField,
    Create,
    Edit,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    BooleanInput, ReferenceField, SelectField, useRecordContext, UrlField, ReferenceArrayInput, SelectArrayInput
} from 'react-admin';
import CreateObjectTransform from "../../CreateObjectTransform";


// const VideoField = (props) => {
//     const record = useRecordContext(props);
//     return <video src={`${record.video}`} controls width="120" height="160"></video>;
// }

const CustomDateField = (props) => {
    const record = useRecordContext(props);
    return <span
        source={new Date(record.createdAt * 1000).toLocaleString()}> {new Date(record.createdAt * 1000).toLocaleString()} </span>
}

export const QuestionList = props => {
    console.log("PROPS", props)
    return <List {...props} perPage={100} sort={{ field: 'createdAt', order: 'desc' }}>
        <Datagrid rowClick="edit" >
            <CustomDateField source="createdAt" label="Created date"/>
            <TextField source="id"/>
            <TextField source="question"/>
            <ReferenceField reference="users" source="authorId" label="Question Author">
                <TextField source="id"/>
            </ReferenceField>
            <BooleanField source="isAdminApproved"/>
            <BooleanField source="isBlocked"/>
            <BooleanField source="isDeleted"/>
            <NumberField source="likes"/>
            <NumberField source="answersCount"/>
        </Datagrid>
    </List>
}

export const QuestionEdit = props => (<Edit {...props}>
        <SimpleForm>
            <TextInput source="question"/>
            <BooleanInput source="isAdminApproved"/>
            <BooleanInput source="isBlocked" />
            <TextInput source="id" disabled/>
        </SimpleForm>
    </Edit>
)
