import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    Edit,
    SimpleForm,
    TextInput,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';


export const LegalDocsList = props => (<List {...props} perPage={100}>
    <Datagrid rowClick="edit">
        <TextField source="id" label="Name"/>
        <TextField source="text" label="Text"/>
        <TextField source="url" label="Link"/>
        <TextField source="date" label="Date"/>
        <TextField source="comment" label="Comment"/>
    </Datagrid>
</List>)

export const LegalDocsEdit = props => (<Edit {...props}>
    <SimpleForm>
        <TextInput source="id" disabled/>
        <RichTextInput source="text" label="Text"/>
        <TextInput source="url" label="Link"/>
        <TextInput source="date" label="Date"/>
        <TextInput source="comment" label="Comment"/>
    </SimpleForm>
</Edit>);
