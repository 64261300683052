import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    BooleanField,
    NumberField,
    ImageField,
    DateField,
    Create,
    Edit,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    BooleanInput, ReferenceField, SelectField, useRecordContext, UrlField
} from 'react-admin';
import CreateObjectTransform from "../../CreateObjectTransform";


const VideoField = (props) => {
    const record = useRecordContext(props);
    return <video src={`${record.video}`} controls width="120" height="160"></video>;
}

const CustomDateField = (props) => {
    const record = useRecordContext(props);
    return <span source={new Date(record.createdAt * 1000).toLocaleString()}> {new Date(record.createdAt * 1000).toLocaleString()} </span>
}

export const ReportsList = props => {
    console.log("PROPS",props)
    return <List {...props} perPage={100}>
        <Datagrid rowClick="edit">
            <CustomDateField source="createdAt" label="Created date"/>
            <ReferenceField reference="products" source="productId">
                <VideoField source="preview"/>
            </ReferenceField>
            <ReferenceField reference="products" source="productId">
                <TextField source="id"/>
            </ReferenceField>
            <ReferenceField reference="users" source="sellerId" label="Owner">
                <TextField source="id"/>
            </ReferenceField>
            <TextField source="reason" title="Reason"/>
            <ReferenceField reference="users" source="userId" label="Sender">
                <TextField source="id"/>
            </ReferenceField>
            <TextField source="status" title="Status"/>
        </Datagrid>
    </List>
}

export const ReportEdit = props => (<Edit {...props}>
    <SimpleForm>
        <h1>User Report</h1>
        <h3>Created Date Report</h3>
        <CustomDateField source="createdDate"/>
        <h3>Product Preview</h3>
        <ReferenceField reference="products" source="productId">
            <VideoField source="preview"/>
        </ReferenceField>
        <h3>Product Id</h3>
        <ReferenceField reference="products" source="productId">
            <TextField source="id"/>
        </ReferenceField>
        <h3>Seller ID</h3>
        <ReferenceField reference="users" source="sellerId" label="Owner">
            <TextField source="id"/>
        </ReferenceField>
        <h3>Reason</h3>
        <TextField source="reason" title="Reason"/>
        <h3>Reporter ID</h3>
        <ReferenceField reference="users" source="userId" label="Sender">
            <TextField source="id"/>
        </ReferenceField>
        <h3>Report Status</h3>
        <SelectInput
            source="status" choices={[
            {id: 'ACCEPTED', name: 'ACCEPTED'},
            {id: 'IN_REVIEW', name: 'IN_REVIEW'},
            {id: 'DECLINED', name: 'DECLINED'}]}/>
    </SimpleForm>
</Edit>);
