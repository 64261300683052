import * as React from "react";
import {
    List,
    Datagrid,
    ImageField,
    TextField,
    BooleanField,
    Edit,
    SimpleForm,
    BooleanInput,
    TextInput, Toolbar,
    SaveButton,
    DeleteButton, useRecordContext, useNotify,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import {initializeApp} from 'firebase/app';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {config} from '../../firebaseConfig'
import {useNavigate} from "react-router-dom";

const app = initializeApp(config);
const functions = getFunctions(app);

function removeUserData(userRecord, navigate, notify) {
    console.log("Start remove User", userRecord)
    const userId = userRecord.id
    console.log("PRINT USER ID", userId)
    const requestRemoveUserFromAdmin = httpsCallable(functions, 'requestRemoveUserFromAdmin');
    requestRemoveUserFromAdmin({userId: userId})
        .then((result) => {
            // Read result of the Cloud Function.
            const data = result.data;
            console.log("Starting removing user process", data)
            notify('Starting remove user data');
            navigate(-1);
        });

}

const userFilters = [
    <TextInput source="id" label="Search by Id" alwaysOn/>,
    <TextInput source="name" label="Search by Name" alwaysOn/>,
    <TextInput source="email" label="Search by Email" alwaysOn/>,
];

const CustomToolbar = props => {
    const record = useRecordContext();
    const navigate = useNavigate();
    const notify = useNotify();

    return <Toolbar
        {...props}
        sx={{display: 'flex', justifyContent: 'space-between'}}
    >
        <SaveButton/>
        <DeleteButton mutationMode="optimistic"/>
        <Button color="primary" onClick={() => removeUserData(record, navigate, notify)}>Remove User Data!!!</Button>
    </Toolbar>
}

export const UserList = props => (
    <List {...props} perPage={100} filters={userFilters}>
        <Datagrid rowClick="edit">
            <BooleanField source="isBlocked"/>
            <BooleanField source="isDeleted"/>
            <ImageField source="avatarUrl"/>
            <TextField source="name" title="Name"/>
            <TextField source="email" title="Email"/>
            <TextField source="authType"/>
            <TextField source="id"/>
            <TextField source="bio" title="bio"/>
            <TextField source="dateOfBirth" title='Date of birth'/>
            <TextField source="zipCode" title="Zip code"/>
            <TextField source="removeStatus" title="Remove Status"/>
        </Datagrid>
    </List>)

export const UserEdit = props => (
    <Edit {...props}>
        <SimpleForm toolbar={<CustomToolbar/>}>
            <TextInput source="id" disabled/>
            <TextInput source="authType" disabled/>
            <TextField source="name" title="Name"/>
            <TextField source="email" title="Email"/>
            <TextField source="bio" title="bio"/>
            <TextField source="dateOfBirth" title='Date of birth'/>
            <TextField source="zipCode" title="Zip code"/>
            <BooleanInput source="isBlocked"/>
        </SimpleForm>
    </Edit>
);
