import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    BooleanField,
    NumberField,
    ImageField,
    DateField,
    Edit,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    BooleanInput, ReferenceField, SelectField, useRecordContext, UrlField, Create
} from 'react-admin';
import CreateObjectTransform from "../../CreateObjectTransform";

const tagsFilters = [
    <TextInput source="id" label="Search by tag" alwaysOn/>,
    <BooleanInput source="isBlocked" optionText="isBlocked"/>,

];


export const TagsList = props => (<List {...props} perPage={100} filters={tagsFilters}>
    <Datagrid rowClick="edit">
        <BooleanField source="isBlocked"/>
        <TextField source="id"/>
    </Datagrid>
</List>)

export const TagEdit = props => (<Edit {...props}>
    <SimpleForm>
        <TextInput source="id" disabled/>
        <BooleanInput source="isBlocked"/>
    </SimpleForm>
</Edit>);
