//Stage Config
// export const config = {
//     apiKey: "AIzaSyDQoDaKLMWVfLxnJUptVBrRvc4HCJmfkhc",
//     authDomain: "yubstart-stage.firebaseapp.com",
//     projectId: "yubstart-stage",
//     storageBucket: "yubstart-stage.appspot.com",
//     messagingSenderId: "29884129786",
//     databaseURL: "https://yubstart-stage.firebaseio.com",
// };


//Prod Config
export const config = {
    apiKey: "AIzaSyAWczIVuGZWUhH4Nkbyq3KNd-y7c_voELU",
    authDomain: "yubstar-ad6e6.firebaseapp.com",
    projectId: "yubstar-ad6e6",
    storageBucket: "yubstar-ad6e6.appspot.com",
    messagingSenderId: "278562354415",
    appId: "1:278562354415:web:6e6ffc5619c2ca4d08d71a",
    measurementId: "G-8C2EE5SBV7"
}
