import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    BooleanField,
    NumberField,
    ImageField,
    DateField,
    Create,
    Edit,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    BooleanInput, ReferenceField, SelectField, useRecordContext, UrlField
} from 'react-admin';
import CreateObjectTransform from "../../CreateObjectTransform";


// const VideoField = (props) => {
//     const record = useRecordContext(props);
//     return <video src={`${record.video}`} controls width="120" height="160"></video>;
// }

const CustomDateField = (props) => {
    const record = useRecordContext(props);
    return <span source={new Date(record.createdAt * 1000).toLocaleString()}> {new Date(record.createdAt * 1000).toLocaleString()} </span>
}

export const QuestionReportsList = props => {
    console.log("PROPS",props)
    return <List {...props} perPage={100}>
        <Datagrid rowClick="edit">
            <CustomDateField source="createdAt" label="Created date"/>
            <ReferenceField reference="community_questions" source="questionId">
                <TextField source="question"/>
            </ReferenceField>
            <ReferenceField reference="community_questions" source="questionId">
                <TextField source="id"/>
            </ReferenceField>
            <ReferenceField reference="users" source="questionAuthorId" label="Question Author">
                <TextField source="id"/>
            </ReferenceField>
            <ReferenceField reference="users" source="userId" label="Sender">
                <TextField source="id"/>
            </ReferenceField>
            <TextField source="status" title="Status"/>
        </Datagrid>
    </List>
}

export const QuestionReportEdit = props => (<Edit {...props}>
    <SimpleForm>
        <h1>Question Report</h1>
        <h3>Report Created Date</h3>
        <CustomDateField source="createdAt"/>
        <h3>Question Text</h3>
        <ReferenceField reference="community_questions" source="questionId" label="Question Text">
            <TextField source="question"/>
        </ReferenceField>
        <h3>Question Id</h3>
        <ReferenceField reference="community_questions" source="questionId">
            <TextField source="id"/>
        </ReferenceField>
        <h3>Question Author Id</h3>
        <ReferenceField reference="users" source="questionAuthorId" label="Question Author">
            <TextField source="id"/>
        </ReferenceField>
        <h3>Reporter ID</h3>
        <ReferenceField reference="users" source="userId" >
            <TextField source="id" />
        </ReferenceField>
        <h3>Status</h3>
        <SelectInput
            source="status" choices={[
            {id: 'ACCEPTED', name: 'ACCEPTED'},
            {id: 'IN_REVIEW', name: 'IN_REVIEW'},
            {id: 'DECLINED', name: 'DECLINED'}]}/>
    </SimpleForm>
</Edit>);
